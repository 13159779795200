import React, {Component} from 'react';
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import Bouton from "../../../../UI/Bouton/Bouton";
import { RiDeleteBin2Fill } from "react-icons/ri";
import * as S from './FormulaireCourbe.module.scss';
import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import {confirmeOnClick} from "../../../../../ressources/functionJS/confirmeOnClick";
import * as type_btn from "../../../../../ressources/glossaires/glossaire_type_de_bouton";
import FormulaireSelectionCapteur from "../../../../UI/FormulaireSelectionCapteur/FormulaireSelectionCapteur";
import FormulaireSelectionComposantesGraphes
    from "../../../../UI/FormulaireSelectionComposantesGraphes/FormulaireSelectionComposantesGraphes";
import * as glossaire_type_de_graphe from "../../../../../ressources/glossaires/glossaire_type_de_graphe";
import { FiSave, FiEdit, FiMinusCircle, FiPlusCircle } from "react-icons/fi";

const decomposeStringMode = (str,modeStr) =>{
    if(str!==undefined){
        const arrayMode = str.split("+");
        return arrayMode.indexOf(modeStr) > -1;
    }else {
        return false;
    }
}
class FormulaireCourbe extends Component {

    state={
        newFormule:"",
        sensor_id_select: "",
        listeComposantes:[],
        sensor_id: null,
        positions_in_sensor_list: [],
        courbe_name: this.props.preConfigCourbe.name,
        formula: this.props.preConfigCourbe.formula,
        parameters: this.props.preConfigCourbe.parameters,
        show_alarms: this.props.preConfigCourbe.show_alarms,
        yaxis: this.props.preConfigCourbe.yaxis,
        type_courbe: this.props.preConfigCourbe.type,
        //mode: lines ou markers ou text
        lines: decomposeStringMode(this.props.preConfigCourbe.mode, "lines"),
        markers: decomposeStringMode(this.props.preConfigCourbe.mode, "markers"),
        text: decomposeStringMode(this.props.preConfigCourbe.mode, "text"),
        //configuration de line{dash:...,shape...}
        line_dash: this.props.preConfigCourbe.line!==undefined?this.props.preConfigCourbe.line.dash:"",
        line_shape: this.props.preConfigCourbe.line!==undefined?this.props.preConfigCourbe.line.shape:"",
        camp:false,
        //mise en page de l'affichage
        affichage_details: false,
        afficherFormulaireFormula: false,
        parametrosCurba: false,
       
    }

    componentDidMount() {
        // this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(this.props.preconfigurerUniteDeTempsId!==prevProps.preconfigurerUniteDeTempsId){
        //     this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
        // }
        

        if(!jsDictionaryEquals(this.props,prevProps)){
            //met à jour l'affichage de la courbe si les configurations de graphe changent
            this.setState({
                courbe_name: this.props.preConfigCourbe.name,
                formula: this.props.preConfigCourbe.formula,
                parameters: this.props.preConfigCourbe.parameters,
                show_alarms: this.props.preConfigCourbe.show_alarms,
                yaxis: this.props.preConfigCourbe.yaxis,
                type_courbe: this.props.preConfigCourbe.type,
                //mode: lines ou markers ou text
                lines: decomposeStringMode(this.props.preConfigCourbe.mode, "lines"),
                markers: decomposeStringMode(this.props.preConfigCourbe.mode, "markers"),
                text: decomposeStringMode(this.props.preConfigCourbe.mode, "text"),
                //configuration de line{dash:...,shape...}
                line_dash: this.props.preConfigCourbe.line!==undefined?this.props.preConfigCourbe.line.dash:"",
                line_shape: this.props.preConfigCourbe.line!==undefined?this.props.preConfigCourbe.line.shape:"",

                //mise en page de l'affichage
                affichage_details: false,
            })
        }
        if(!jsDictionaryEquals(this.state,prevState)){
            let newMode = [];
            if(this.state.lines){
                newMode.push("lines");
            }
            if(this.state.markers){
                newMode.push("markers");
            }
            if(this.state.text){
                newMode.push("text");
            }
            this.props.gererConfigCourbe({
                //config générale d'une courbe
                "name": this.state.courbe_name, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: nom de la courbe
                "show_alarms": parseInt(this.state.show_alarms), //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
                "formula": this.state.formula, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
                "parameters": this.state.parameters, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
                "yaxis": this.state.yaxis, //TYPE: String + ValeurParDéfaut: "y1" +  DESCRIPTION: "y1" ou "y2"
                "type": this.state.type_courbe, //TYPE: String + ValeurParDéfaut: "scatter" +  DESCRIPTION: "scatter" ou "bar"

                //configuration pour un type de courbe scatter
                "mode": newMode.join("+"), //TYPE: String + ValeurParDéfaut: "lines+makers" +  DESCRIPTION: 'lines et/ou markers et/ou text' concaténer avec un "+"

                //configuration pour un mode incluant line
                "line": { //TYPE: Object + ValeurParDéfaut: {} +  DESCRIPTION: si mode contient "line"
                    "dash": this.state.line_dash, //TYPE: String + ValeurParDéfaut: "linear" +  DESCRIPTION: 'vh' ou 'hv' ou 'linear' ou 'spline' ou 'hvh' ou 'vhv'
                    "shape": this.state.line_shape, //TYPE: String + ValeurParDéfaut: "solid" +  DESCRIPTION:  'solid' ou 'dashdot' ou 'dot'
                },
            });
        }
    }
    
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererObjetEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: event.target.value,
    });
    gererEtatCheckboxDeCibleName = changeEvent => {
        const { name } = changeEvent.target;
        this.setState(prevState => ({
                ...prevState,
                [name]: !prevState[name]
        }));
    };
    gererPositionsInSensorList = (positions_in_sensor_list) => {
        this.setState({...this.state,
             positions_in_sensor_list: positions_in_sensor_list, 
             formula:`#${this.state.sensor_id},${positions_in_sensor_list}#`,
             afficherFormulaireFormula :true
            })
    };
    gererCapteurId = (sensor_id, listeComposantes) => {
        this.setState({...this.state, 
            sensor_id: sensor_id, listeComposantes: listeComposantes,
            formula:`#${sensor_id},${this.state.positions_in_sensor_list}#`,
            afficherFormulaireFormula :true
        })
    };
    gererFormula = (formule) => this.setState({
        ...this.state,
        formula: formule,
    });
    switchAfficherFormulaireFormula = () => this.setState({
        ...this.state,
        afficherFormulaireFormula: !this.state.afficherFormulaireFormula,
    });
    showCamp = () => this.setState({
        ...this.state,
        camp: true,
    });
    showCampOFF = () => this.setState({
        ...this.state,
        camp: false,
    }); 
    
    parametrosCurba

    ONparametrosCurba = () => this.setState({
        ...this.state,
        parametrosCurba: true,
    });
    OffparametrosCurba = () => this.setState({
        ...this.state,
        parametrosCurba: false,
    }); 

    afficherDetail = () => this.setState({...this.state, affichage_details: !this.state.affichage_details});
    handleSubcomponentSelection = (id, composant) => {
        // Atualize o valor da fórmula com o ID e o componente selecionado
        this.setState({
          newFormule: this.state.newFormule + `#${id},${composant}#`
        });
      }
       getValue = () => {
        const sensor_id = this.state.sensor_id !== undefined ? this.state.sensor_id : '';
        const positions_in_sensor_list = this.state["positions_in_sensor_list"] !== undefined ? this.state["positions_in_sensor_list"] : '';
        return `#${sensor_id},${positions_in_sensor_list}#`;
      }
    render(){
     
        // récupérer liste des UnitesDeTemps avec this.props.listeUnitesDeTemps
        // créer un formulaire pour ajouter un UniteDeTemps à un capteur sous forme de liste déroulante
        const detailsCourbe = <Auxiliaire>{this.state.parametrosCurba === false ?
            <button className={[S.btspecielNew].join(" ")} onClick={this.ONparametrosCurba}>
            {checkMot("parameters_avance")} <FiPlusCircle size={25}/>
            </button>
   : <div className={S.magin}>
   {/**DEBUT parameters**/}
   
<div className={[s.row].join(" ")}>
           <div className={s.col_12}>
               <label className={[s.text_left, S.marge]}>{checkMot("parameters")} {
               this.state.camp === false ?
               <button className={S.btspeciel}  onClick={this.showCamp} ><FiPlusCircle size={15}/> </button>
                :
                <button className={S.btspeciel}   onClick={this.showCampOFF}> <FiMinusCircle size={15}/></button>   
               }</label>
              {this.state.camp === true ? <input
                   type="text"
                   pattern="[0-9a-zA-Z._%+-]+"
                   className={s.form_control}
                   placeholder="parameters"
                   name="parameters"
                   value={this.state.parameters}
                   disabled={this.props.disabled===true}
                   // required
                   onChange={(event) => this.gererEtatDeCibleName(event)}/>:null } 
           </div>
       


   <div className={[s.row, S.marge].join(" ")}>
       {/**DEBUT show_alarms**/}
       <div className={s.col_5}>
       <span>{checkMot("seuils")}</span>
       <select
           onChange={(event) => this.gererEtatDeCibleName(event)}
           name="show_alarms"
           defaultValue={this.state.show_alarms}
           disabled={this.props.disabled===true}
           value={this.state.show_alarms}
           className={s.custom_select}>
           <option value={1}>{checkMot("afficher")}</option>
           <option value={0}>{checkMot("masquer")}</option>
       </select>{/**FIN show_alarms**/}
       </div>
   {/* </div>
   <div className={[s.col_12].join(" ")}> */}
       {/**DEBUT yaxis**/}
       <div className={s.col_7}>
       <span>{checkMot("yaxis")}</span>
       <select
           onChange={(event) => this.gererEtatDeCibleName(event)}
           name="yaxis"
           defaultValue={this.state.yaxis}
           disabled={this.props.disabled===true}
           value={this.state.yaxis}
           className={s.custom_select}>
           <option value={"y1"}>{checkMot("y1")}</option>
           <option value={"y2"}>{checkMot("y2")}</option>
       </select>{/**FIN yaxis**/}
       </div>
   </div>


<div className={[s.col_12].join(" ")}>
   {/**DEBUT type_courbe**/}
   <label className={[s.text_left, S.marge].join(" ")}>{checkMot("type_de_courbe")}</label>
   <select
       onChange={(event) => this.gererEtatDeCibleName(event)}
       name="type_courbe"
       defaultValue={this.state.type_courbe}
       disabled={this.props.disabled===true}
       value={this.state.type_courbe}
       className={[s.custom_select, S.marge].join(" ")}>
       <option className={S.optios} value="scatter">{checkMot("scatter")}</option>
       <option className={S.optios} value="bar">{checkMot("bar")}</option>
   </select>{/**FIN type_courbe**/}
</div>
</div>

{//configuration du mode de courbe si type==scatter
   this.state.type_courbe === "scatter"
       ?<Auxiliaire>
           <div className={S.optios}><input name="lines" checked={this.state.lines}
                         type="checkbox" onChange={this.gererEtatCheckboxDeCibleName} disabled={this.props.disabled===true}/>
               {checkMot("lines")}</div>
           <div className={S.optios}><input name="markers" checked={this.state.markers}
                         type="checkbox" onChange={this.gererEtatCheckboxDeCibleName} disabled={this.props.disabled===true}/>
               {checkMot("markers")}</div>
           <div className={S.optios}><input name="text" checked={this.state.text}
                         type="checkbox" onChange={this.gererEtatCheckboxDeCibleName} disabled={this.props.disabled===true}/>
               {checkMot("text")}</div>
           {//configuration des lignes de courbe si ligne est présent dans mode: "lines+..."
               this.state.lines
                   ? <div className={[s.text_left].join(" ")}>
                       {/**DEBUT line_dash**/}
                       <div className={s.col_12}>{checkMot("dash")}</div>
                       <select
                           onChange={(event) => this.gererEtatDeCibleName(event)}
                           name="line_dash"
                           defaultValue={this.state.line_dash}
                           disabled={this.props.disabled === true}
                           value={this.state.line_dash}
                           className={s.custom_select}>
                           <option value="solid">{checkMot("solid")}</option>
                           <option value="dot">{checkMot("dot")}</option>
                           <option value="dash">{checkMot("dash")}</option>
                           <option value="longdash">{checkMot("longdash")}</option>
                           <option value="dashdot">{checkMot("dashdot")}</option>
                           <option value="longdashdot">{checkMot("longdashdot")}</option>
                       </select>{/**FIN line_dash**/}
                       {/**DEBUT line_shape**/}
                       <div className={s.col_12}>{checkMot("shape")}</div>
                       <select
                           onChange={(event) => this.gererEtatDeCibleName(event)}
                           name="line_shape"
                           defaultValue={this.state.line_shape}
                           disabled={this.props.disabled === true}
                           value={this.state.line_shape}
                           className={s.custom_select}>
                           <option value="vh">{checkMot("vh")}</option>
                           <option value="hv">{checkMot("hv")}</option>
                           <option value="linear">{checkMot("linear")}</option>
                           <option value="spline">{checkMot("spline")}</option>
                           <option value="hvh">{checkMot("hvh")}</option>
                           <option value="vhv">{checkMot("vhv")}</option>
                       </select>{/**FIN line_shape**/}
                   </div>//Configuration de line_dash et line_shape
                   : null}
       </Auxiliaire>
       :null
}
</div>}
        </Auxiliaire>
      
        return(
            <Auxiliaire>

                    {/*config générale d'une courbe*/}
                    {/**DEBUT courbe_name && formula**/}
                        <div className={s.row}>
                            <div className={[s.col_12, S.sti1].join(" ")}>
                         
                            <div className={s.col_12}>
                                <label className={S.text}>{checkMot("nom_courbe")}</label>
                                <input
                                    type="text"
                                    pattern="[0-9a-zA-Z._%+-]+"
                                    className={[s.form_control].join(" ")}
                                    placeholder="courbe_name"
                                    name="courbe_name"
                                    value={this.state.courbe_name}
                                    disabled={this.props.disabled===true}
                                    // required
                                    onChange={(event) => this.gererEtatDeCibleName(event)}/>
                            </div>

                            {/* ----------------------------formula--start---------------------------------- */}
                    {    this.state.formula === "" || this.state.afficherFormulaireFormula?
                         
                            <div className={S.styleNes}>   
                             <label className={S.text}>{checkMot("formula")}</label>       
                                  { parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_t ? <FormulaireSelectionCapteur gererCapteurId={this.gererCapteurId} preconfigurerCapteurId={this.state.sensor_id}/> :null}
                                  { parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_t ?   <FormulaireSelectionComposantesGraphes gererPositionsInSensorList={this.gererPositionsInSensorList} disable={isNaN(this.state.sensor_id)} preconfigurerPositionsInSensorList={this.state.positions_in_sensor_list} listeComposantes={this.state.listeComposantes}/>: null} 
                                    <div className={[s.col_12].join(" ")}>
                                    { parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_y ||  parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_y_t ? 
                                            <textarea
                                                className={[s.form_control, S.specialYell, S.textLarge ].join(" ")}
                                                placeholder="formula"
                                                name="formula"
                                                value={this.state.formula}
                                                disabled={this.props.disabled===true}
                                                onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>
                                                :
                                                <input
                                                type="text"
                                                className={[s.form_control, S.specialYello].join(" ")}
                                                placeholder="formula"
                                                name="formula"
                                                value={this.state.formula}
                                                disabled={this.props.disabled===true}
                                                onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>}
                                    </div> 
                                       
                                    { parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_t  ? <Bouton 
                                     ajoutClass={S.stilees}
                                        type={type_btn.succes}
                                        desactive={this.props.disabled===true} 
                                        cliquer={this.switchAfficherFormulaireFormula}

                                    >
                                        <FiSave size={17}/>
                                        </Bouton> :null}
                           </div>
                           :
                           <div className={[s.col_12].join(" ")}>
                           <label className={S.text}>{checkMot("formula")}</label>
                           
                           { parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_y ||  parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_y_t ? 
                                            <textarea
                                                className={[s.form_control, S.textLarge ].join(" ")}
                                                placeholder="formula"
                                                name="formula"
                                                value={this.state.formula}
                                                disabled={this.props.disabled===true}
                                                onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>
                                                :
                                                <input
                                                type="text"
                                                className={[s.form_control].join(" ")}
                                                placeholder="formula"
                                                name="formula"
                                                value={this.state.formula}
                                                disabled={this.props.disabled===true}
                                                onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>}
                               {this.props.disabled===true || this.state.listeComposantes ===""  ? null: parseInt(this.props.typoGraphe) === glossaire_type_de_graphe.graphe_x_t ? <Bouton ajoutClass={S.stilees2} type={type_btn.danger}  cliquer={this.switchAfficherFormulaireFormula}><FiEdit size={17}/></Bouton>:null}
                               
                       </div>
    } 
                            {/* ----------------------------formula--end---------------------------------- */}
                            {/* <Collapse ExpandText={checkMot("parametres_avances")} > */}
                          
                                {detailsCourbe}
                            {/* </Collapse> */}
                            <div className={S.btnSup}>
                            {this.props.disabled ? null
                                :<Bouton  type={type_btn.danger} cliquer={()=>confirmeOnClick(checkMot("voulez_vous_vraiment_supprimer_cette_courbe"),this.props.supprimerCourbe)}>
                                    {/*Bouton supprimer courbe*/}
                                    <span> {<RiDeleteBin2Fill size={16}/>} {checkMot("supprimer")}</span>
                                </Bouton>
                            }</div>
                        </div>
                    {/**FIN courbe_name && formula**/}

                {/*{this.state.affichage_details?:null}*/}
                        </div>
            </Auxiliaire>
        );
    }
}

export default FormulaireCourbe;