import React, {Component} from 'react';
import {connect} from "react-redux";

import AffichageVue from "../../../AffichageVue/AffichageVue";
import Bouton from "../../../../UI/Bouton/Bouton";
import Auxiliaire from "../../../../../hoc/Auxiliaire/Auxiliaire";

import {checkMot} from "../../../../../ressources/functionJS/checkMot";
import {floatOrNull} from "../../../../../ressources/functionJS/floatOrNull";
import {jsDictionaryEquals} from "../../../../../ressources/functionJS/jsDictionaryEquals";
import * as glossaire_type_objets_vue from "../../../../../ressources/glossaires/glossaire_type_objets_vue";
import * as glossaire_pattern from "../../../../../ressources/glossaires/glossaire_pattern";
import * as glossaire_type_d_objet from "../../../../../ressources/glossaires/glossaire_type_d_objet_associe_a_une_vue";
import * as actions from "../../../../../store/actions";

import CatalogueObject from "../../../../CatalogueObject/CatalogueObject"
import {hedes} from "./FormulaireObjet.module.scss";
import {SensorLevel_green} from "../../../AffichageVue/AffichageVue.module.scss"
import * as S from "../../FormulaireVue/FormulaireVue.module.scss";
import * as s from '../../../../../ressources/StyleGlobal/StyleGlobal';
import FormulaireSelectionGraphe from "../../../../UI/FormulaireSelectionGraphe/FormulaireSelectionGraphe";
import FormulaireSelectionCapteur from "../../../../UI/FormulaireSelectionCapteur/FormulaireSelectionCapteur";
import FormulaireSelectionComposantes
    from "../../../../UI/FormulaireSelectionComposantes/FormulaireSelectionComposantes";
    import Popup from "../../../../UI/Popup/Popup";
    import  * as type_btn  from '../../../../../ressources/glossaires/glossaire_type_de_bouton';
    import { FaSwatchbook } from "react-icons/fa";
const valeur_defaut_null = {
    sensor_id: null,
    positions_in_sensor_list: [],
    view_in_view_id: null,
    graph_id: null,
}

class FormulaireObjet extends Component {
    state = {
        viewobj_type: this.props.preconfigurerObjet !== undefined ? this.props.preconfigurerObjet.viewobj_type : glossaire_type_d_objet.capteur,
        sensor_id: null,
        positions_in_sensor_list: [],
        graph_id: null,
        view_in_view_id: null,
        get_values_onload: 1,
        get_metadata_onload: 1,
        flag_coord_reel: 0,
        projection_id: null,
        coord_x: 0,
        coord_y: 0,
        coord_z: 0,
        viewobj_path: "",
        type_affichage: null,
        listeComposantes:[]
    }

    componentDidMount() {
        if (this.props !== undefined && this.props.preconfigurerObjet === undefined) {
            //initialise les valeur par défaut du formulaire
            this.setState({
                //todo: paramètre par défaut de l'objet
                ...this.state,
                viewobj_json_content: {},
                theProps: {
                    "sensor_color_class": SensorLevel_green,
                    "listeDernieresDonnees": [
                        {
                            "x": [
                                1617562800
                            ],
                            "y": [
                                1
                            ],
                            "prefix": "x1",
                            "position_in_sensor": 1,
                            "engineering_unit": "mm"
                        },
                        {
                            "x": [
                                1617562800
                            ],
                            "y": [
                                13.73
                            ],
                            "prefix": "x2",
                            "position_in_sensor": 2,
                            "engineering_unit": "cm"
                        }
                    ],
                    "objetAAfficher": {
                        "flag_coord_reel": 1,
                        "projection_id": 0,
                        "coord_x": 500.85661,
                        "coord_y": 500.351499,
                        "coord_z": 0,
                        "name": "nomMarker1",
                        "icone_path": "./../api_argos/image/sensor.svg",
                        "sensor_type_display": 1,
                        "viewobj_type": this.state.viewobj_type,
                        "sensor_id": 1,
                        "positions_in_sensor_list": [
                            1,
                            2
                        ],
                        "graph_id": 1,
                        "view_in_view_id": null,
                        "get_values_onload": 1,
                        "data": [
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    1
                                ],
                                "prefix": "x1",
                                "position_in_sensor": 1,
                                "engineering_unit": "mm"
                            },
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    13.73
                                ],
                                "prefix": "x2",
                                "position_in_sensor": 2,
                                "engineering_unit": "cm"
                            }
                        ],
                        "get_metadata_onload": 1,
                        "metadata": {
                            "project_id": 10,
                            "name": "xxx",
                            "id_type": "xxx",
                            "sensor_group": "xxx",
                            "remarks": "xxx",
                            "sensor_projection": "xxx",
                            "coordinate_x": 48.85661,
                            "coordinate_y": 2.351499,
                            "coordinate_z": 10.5,
                            "components": [
                                {
                                    "position_in_sensor": 1,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x1",
                                    "engineering_unit": "mm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": 10,
                                    "alarmlevel_low4": null
                                },
                                {
                                    "position_in_sensor": 2,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x2",
                                    "engineering_unit": "cm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": null,
                                    "alarmlevel_low4": null
                                }
                            ]
                        }
                    },
                    "dimensionsIcon": 30,
                    "viewobj_content_list": {}
                }
            })
        } else if (this.props !== undefined && this.props.preconfigurerObjet !== undefined) {
            //initialiser le formulaire avec les valeurs récupéré

            let theProps = {
                "sensor_color_class": SensorLevel_green,
                "listeDernieresDonnees": [
                    {
                        "x": [
                            1617562800
                        ],
                        "y": [
                            1
                        ],
                        "prefix": "x1",
                        "position_in_sensor": 1,
                        "engineering_unit": "mm"
                    },
                    {
                        "x": [
                            1617562800
                        ],
                        "y": [
                            13.73
                        ],
                        "prefix": "x2",
                        "position_in_sensor": 2,
                        "engineering_unit": "cm"
                    }
                ],
                "dimensionsIcon": 30,
                "viewobj_content_list": {...this.state.viewobj_content_list}
            }
            switch (this.props.preconfigurerObjet.viewobj_type) {
                case glossaire_type_d_objet.capteur:
                    theProps["objetAAfficher"] = {
                        "viewobj_id": this.props.preconfigurerObjet.viewobj_id,
                        "flag_coord_reel": 1,
                        "projection_id": 0,
                        "coord_x": 500.85661,
                        "coord_y": 500.351499,
                        "coord_z": 0,
                        "name": "nomMarker1",
                        "viewobj_path": "http://localhost/api_argos/exemple/objetsVue/gif.json",
                        "icone_path": "./../api_argos/image/sensor.svg",
                        "sensor_type_display": 0,
                        "viewobj_type": this.state.viewobj_type,
                        "sensor_id": 1,
                        "positions_in_sensor_list": [
                            1,
                            2
                        ],
                        "graph_id": 1,
                        "view_in_view_id": null,
                        "get_values_onload": 1,
                        "data": [
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    1
                                ],
                                "prefix": "x1",
                                "position_in_sensor": 1,
                                "engineering_unit": "mm"
                            },
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    13.73
                                ],
                                "prefix": "x2",
                                "position_in_sensor": 2,
                                "engineering_unit": "cm"
                            }
                        ],
                        "get_metadata_onload": 1,
                        "metadata": {
                            "project_id": 10,
                            "name": "xxx",
                            "id_type": "xxx",
                            "sensor_group": "xxx",
                            "remarks": "xxx",
                            "sensor_projection": "xxx",
                            "coordinate_x": 48.85661,
                            "coordinate_y": 2.351499,
                            "coordinate_z": 10.5,
                            "components": [
                                {
                                    "position_in_sensor": 1,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x1",
                                    "engineering_unit": "mm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": 10,
                                    "alarmlevel_low4": null
                                },
                                {
                                    "position_in_sensor": 2,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x2",
                                    "engineering_unit": "cm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": null,
                                    "alarmlevel_low4": null
                                }
                            ]
                        }
                    };
                    break;
                case glossaire_type_d_objet.graphe:
                    theProps["objetAAfficher"] = {
                        "viewobj_id": 4,
                        "flag_coord_reel": 0,
                        "projection_id": 0,
                        "coord_x": 500.85061,
                        "coord_y": 100.361309,
                        "coord_z": 0,
                        "name": "graphe1",
                        "viewobj_path": "http://localhost/api_argos/exemple/objetsVue/affichage_graphe.json",
                        "icone_path": "./../api_argos/image/img_565375.png",
                        "viewobj_type": 2,
                        "graph_id": 1,
                        "sensor_id": null,
                        "positions_in_sensor_list": [],
                        "view_in_view_id": null,
                        "get_values_onload": 1,
                        "data": {
                            "config": {
                                "graph_id": 1,
                                "graph_type_id": 1,
                                "title": "évolution des variables en fonction du temps",
                                "subtitle": "Zone A",
                                "showgrid": 1,
                                "showlegend": 1,
                                "yaxis": {
                                    "title": "Tassement [mm]",
                                    "type": "linear",
                                    "range": [
                                        -30,
                                        30
                                    ]
                                },
                                "yaxis2": {
                                    "title": "Température [°C]",
                                    "type": "linear",
                                    "range": [
                                        -5,
                                        60
                                    ]
                                },
                                "barmode": "stack",
                                "xaxis": {
                                    "title": "Temps",
                                    "type": "date",
                                    "range": [
                                        null,
                                        null
                                    ]
                                }
                            },
                            "data": [
                                {
                                    "x": [
                                        1619734500,
                                        1619735400,
                                        1619736300,
                                        1619737200,
                                        1619738100
                                    ],
                                    "y": [
                                        1,
                                        5,
                                        6,
                                        7,
                                        9
                                    ],
                                    "name": "courbe 1",
                                    "sensor_name": "capteur1",
                                    "prefix": "température",
                                    "position_in_sensor": 1,
                                    "engineering_unit": "°C",
                                    "yaxis": "y2",
                                    "type": "scatter",
                                    "mode": "lines+markers",
                                    "line": {
                                        "shape": "vh",
                                        "dash": "dot"
                                    }
                                }
                            ],
                            "alarms_levels": [
                                {
                                    "yaxis": "y2",
                                    "sensor_name": "date nomDuCapteur",
                                    "prefix": "",
                                    "position_in_sensor": 1,
                                    "engineering_unit": "mm",
                                    "type": "scatter",
                                    "mode": "markers",
                                    "line": {
                                        "shape": "vh",
                                        "dash": "dot"
                                    },
                                    "data": [
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -5,
                                                -5,
                                                -5,
                                                -15,
                                                -15,
                                                -15
                                            ],
                                            "line_color": "black",
                                            "name": "seuilH4"
                                        },
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -10,
                                                -10,
                                                -10,
                                                -20,
                                                -20,
                                                -20
                                            ],
                                            "line_color": "red",
                                            "name": "seuilH3"
                                        },
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -5,
                                                -5,
                                                -5,
                                                -5,
                                                -5,
                                                -5
                                            ],
                                            "line_color": "blue",
                                            "name": "seuilH1"
                                        }
                                    ]
                                }
                            ]
                        },
                        "get_metadata_onload": 1,
                        "metadata": {
                            "project_id": 10,
                            "name": "xxx",
                            "id_type": "xxx",
                            "sensor_group": "xxx",
                            "remarks": "xxx",
                            "sensor_projection": "xxx",
                            "coordinate_x": 10.5,
                            "coordinate_y": 10.5,
                            "coordinate_z": 10.5,
                            "components": [
                                {
                                    "position_in_sensor": 1,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "dX",
                                    "engineering_unit": "m",
                                    "alarmlevel_high4": 10.5,
                                    "alarmlevel_high3": 8.5,
                                    "alarmlevel_high2": 6.5,
                                    "alarmlevel_high1": 4.5,
                                    "alarmlevel_low1": -4.5,
                                    "alarmlevel_low2": -6.5,
                                    "alarmlevel_low3": -8.5,
                                    "alarmlevel_low4": -10.5
                                },
                                {
                                    "position_in_sensor": 2,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "dY",
                                    "engineering_unit": "m",
                                    "alarmlevel_high4": 10.5,
                                    "alarmlevel_high3": 8.5,
                                    "alarmlevel_high2": 6.5,
                                    "alarmlevel_high1": 4.5,
                                    "alarmlevel_low1": -4.5,
                                    "alarmlevel_low2": -6.5,
                                    "alarmlevel_low3": -8.5,
                                    "alarmlevel_low4": -10.5
                                }
                            ]
                        }
                    }
                    break;
                default:
                    break;
            }
            this.setState({
                //todo: pré-configuration du formulaire
                ...this.state,
                ...this.props.preconfigurerObjet,
                flag_coord_reel: this.props.preconfigurerObjet.flag_coord_reel,
                positions_in_sensor_list: this.props.preconfigurerObjet.positions_in_sensor_list,
                theProps: theProps
            })

            if (this.props.preconfigurerObjet.viewobj_path !== undefined) {
                this.get_viewobj_json_content(this.props.preconfigurerObjet)
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.preconfigurerObjet !== undefined && !jsDictionaryEquals(this.props.preconfigurerObjet, prevProps.preconfigurerObjet)) {
            let theProps = {
                "sensor_color_class": SensorLevel_green,
                "listeDernieresDonnees": [
                    {
                        "x": [
                            1617562800
                        ],
                        "y": [
                            1
                        ],
                        "prefix": "x1",
                        "position_in_sensor": 1,
                        "engineering_unit": "mm"
                    },
                    {
                        "x": [
                            1617562800
                        ],
                        "y": [
                            13.73
                        ],
                        "prefix": "x2",
                        "position_in_sensor": 2,
                        "engineering_unit": "cm"
                    }
                ],
                "dimensionsIcon": 30,
                "viewobj_content_list": {...this.state.viewobj_content_list}
            }
            switch (this.props.preconfigurerObjet.viewobj_type) {
                case glossaire_type_d_objet.capteur:
                    theProps["objetAAfficher"] = {
                        "viewobj_id": this.props.preconfigurerObjet.viewobj_id,
                        "flag_coord_reel": 1,
                        "projection_id": 0,
                        "coord_x": 500.85661,
                        "coord_y": 500.351499,
                        "coord_z": 0,
                        "name": "nomMarker1",
                        "viewobj_path": "http://localhost/api_argos/exemple/objetsVue/gif.json",
                        "icone_path": "./../api_argos/image/sensor.svg",
                        "sensor_type_display": 0,
                        "viewobj_type": this.state.viewobj_type,
                        "sensor_id": 1,
                        "positions_in_sensor_list": [
                            1,
                            2
                        ],
                        "graph_id": 1,
                        "view_in_view_id": null,
                        "get_values_onload": 1,
                        "data": [
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    1
                                ],
                                "prefix": "x1",
                                "position_in_sensor": 1,
                                "engineering_unit": "mm"
                            },
                            {
                                "x": [
                                    1617562800
                                ],
                                "y": [
                                    13.73
                                ],
                                "prefix": "x2",
                                "position_in_sensor": 2,
                                "engineering_unit": "cm"
                            }
                        ],
                        "get_metadata_onload": 1,
                        "metadata": {
                            "project_id": 10,
                            "name": "xxx",
                            "id_type": "xxx",
                            "sensor_group": "xxx",
                            "remarks": "xxx",
                            "sensor_projection": "xxx",
                            "coordinate_x": 48.85661,
                            "coordinate_y": 2.351499,
                            "coordinate_z": 10.5,
                            "components": [
                                {
                                    "position_in_sensor": 1,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x1",
                                    "engineering_unit": "mm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": 10,
                                    "alarmlevel_low4": null
                                },
                                {
                                    "position_in_sensor": 2,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "x2",
                                    "engineering_unit": "cm",
                                    "alarmlevel_high4": null,
                                    "alarmlevel_high3": null,
                                    "alarmlevel_high2": null,
                                    "alarmlevel_high1": null,
                                    "alarmlevel_low1": null,
                                    "alarmlevel_low2": null,
                                    "alarmlevel_low3": null,
                                    "alarmlevel_low4": null
                                }
                            ]
                        }
                    };
                    break;
                case glossaire_type_d_objet.graphe:
                    theProps["objetAAfficher"] = {
                        "viewobj_id": this.props.preconfigurerObjet.viewobj_id,
                        "flag_coord_reel": 0,
                        "projection_id": 0,
                        "coord_x": 500.85061,
                        "coord_y": 100.361309,
                        "coord_z": 0,
                        "name": "graphe1",
                        "viewobj_path": "http://localhost/api_argos/exemple/objetsVue/affichage_graphe.json",
                        "icone_path": "./../api_argos/image/img_565375.png",
                        "viewobj_type": 2,
                        "graph_id": 1,
                        "sensor_id": null,
                        "positions_in_sensor_list": [],
                        "view_in_view_id": null,
                        "get_values_onload": 1,
                        "data": {
                            "config": {
                                "graph_id": 1,
                                "graph_type_id": 1,
                                "title": "évolution des variables en fonction du temps",
                                "subtitle": "Zone A",
                                "showgrid": 1,
                                "showlegend": 1,
                                "yaxis": {
                                    "title": "Tassement [mm]",
                                    "type": "linear",
                                    "range": [
                                        -30,
                                        30
                                    ]
                                },
                                "yaxis2": {
                                    "title": "Température [°C]",
                                    "type": "linear",
                                    "range": [
                                        -5,
                                        60
                                    ]
                                },
                                "barmode": "stack",
                                "xaxis": {
                                    "title": "Temps",
                                    "type": "date",
                                    "range": [
                                        null,
                                        null
                                    ]
                                }
                            },
                            "data": [
                                {
                                    "x": [
                                        1619734500,
                                        1619735400,
                                        1619736300,
                                        1619737200,
                                        1619738100
                                    ],
                                    "y": [
                                        1,
                                        5,
                                        6,
                                        7,
                                        9
                                    ],
                                    "name": "courbe 1",
                                    "sensor_name": "capteur1",
                                    "prefix": "température",
                                    "position_in_sensor": 1,
                                    "engineering_unit": "°C",
                                    "yaxis": "y2",
                                    "type": "scatter",
                                    "mode": "lines+markers",
                                    "line": {
                                        "shape": "vh",
                                        "dash": "dot"
                                    }
                                }
                            ],
                            "alarms_levels": [
                                {
                                    "yaxis": "y2",
                                    "sensor_name": "date nomDuCapteur",
                                    "prefix": "",
                                    "position_in_sensor": 1,
                                    "engineering_unit": "mm",
                                    "type": "scatter",
                                    "mode": "markers",
                                    "line": {
                                        "shape": "vh",
                                        "dash": "dot"
                                    },
                                    "data": [
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -5,
                                                -5,
                                                -5,
                                                -15,
                                                -15,
                                                -15
                                            ],
                                            "line_color": "black",
                                            "name": "seuilH4"
                                        },
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -10,
                                                -10,
                                                -10,
                                                -20,
                                                -20,
                                                -20
                                            ],
                                            "line_color": "red",
                                            "name": "seuilH3"
                                        },
                                        {
                                            "x": [
                                                1615447735,
                                                1615534135,
                                                1615620535,
                                                1615620535,
                                                1615620535,
                                                1615883335
                                            ],
                                            "y": [
                                                -5,
                                                -5,
                                                -5,
                                                -5,
                                                -5,
                                                -5
                                            ],
                                            "line_color": "blue",
                                            "name": "seuilH1"
                                        }
                                    ]
                                }
                            ]
                        },
                        "get_metadata_onload": 1,
                        "metadata": {
                            "project_id": 10,
                            "name": "xxx",
                            "id_type": "xxx",
                            "sensor_group": "xxx",
                            "remarks": "xxx",
                            "sensor_projection": "xxx",
                            "coordinate_x": 10.5,
                            "coordinate_y": 10.5,
                            "coordinate_z": 10.5,
                            "components": [
                                {
                                    "position_in_sensor": 1,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "dX",
                                    "engineering_unit": "m",
                                    "alarmlevel_high4": 10.5,
                                    "alarmlevel_high3": 8.5,
                                    "alarmlevel_high2": 6.5,
                                    "alarmlevel_high1": 4.5,
                                    "alarmlevel_low1": -4.5,
                                    "alarmlevel_low2": -6.5,
                                    "alarmlevel_low3": -8.5,
                                    "alarmlevel_low4": -10.5
                                },
                                {
                                    "position_in_sensor": 2,
                                    "serial_number": "15648645",
                                    "coordinate_x": 0,
                                    "coordinate_y": 0,
                                    "coordinate_z": 0,
                                    "prefix": "dY",
                                    "engineering_unit": "m",
                                    "alarmlevel_high4": 10.5,
                                    "alarmlevel_high3": 8.5,
                                    "alarmlevel_high2": 6.5,
                                    "alarmlevel_high1": 4.5,
                                    "alarmlevel_low1": -4.5,
                                    "alarmlevel_low2": -6.5,
                                    "alarmlevel_low3": -8.5,
                                    "alarmlevel_low4": -10.5
                                }
                            ]
                        }
                    }
                    break;
                case glossaire_type_d_objet.vue:
                    theProps["objetAAfficher"] = {
                        "viewobj_id": this.props.preconfigurerObjet.viewobj_id,
                        "projection_id": 0,
                        "coord_x": 500.85061,
                        "coord_y": 100.361309,
                        "coord_z": 0,
                        "name": "vue1",
                        "viewobj_path": "http://localhost/api_argos/exemple/objetsVue/bouton_de_redirection.json",
                        "icone_path": "./../api_argos/image/img_565375.png",
                        "viewobj_type": 3,
                        "graph_id": null,
                        "sensor_id": null,
                        "positions_in_sensor_list": [],
                        "view_in_view_id": 2,
                    }
                    break;
                default:
                    break;
            }
            this.setState({
                viewobj_type: this.props.preconfigurerObjet.viewobj_type,
                sensor_id: this.props.preconfigurerObjet.sensor_id,
                positions_in_sensor_list: this.props.preconfigurerObjet.positions_in_sensor_list,
                graph_id: this.props.preconfigurerObjet.graph_id,
                view_in_view_id: this.props.preconfigurerObjet.view_in_view_id,
                get_values_onload: this.props.preconfigurerObjet.get_values_onload,
                get_metadata_onload: this.props.preconfigurerObjet.get_metadata_onload,
                flag_coord_reel: this.props.preconfigurerObjet.flag_coord_reel,
                projection_id: this.props.preconfigurerObjet.projection_id,
                coord_x: this.props.preconfigurerObjet.coord_x,
                coord_y: this.props.preconfigurerObjet.coord_y,
                coord_z: this.props.preconfigurerObjet.coord_z,
                theProps: theProps,
            })
            if (this.props.preconfigurerObjet.viewobj_path !== undefined) {
                this.get_viewobj_json_content(this.props.preconfigurerObjet)
            }
        }
        if (this.props.preconfigurerObjet === undefined && !jsDictionaryEquals(this.props.preconfigurerObjet, prevProps.preconfigurerObjet)) {
            this.setState({
                viewobj_type: glossaire_type_d_objet.capteur,
                sensor_id: null,
                positions_in_sensor_list: [],
                graph_id: null,
                view_in_view_id: null,
                get_values_onload: 1,
                get_metadata_onload: 1,
                flag_coord_reel: 1,
                projection_id: null,
                coord_x: 0,
                coord_y: 0,
                coord_z: 0,
                viewobj_path: "",
                type_affichage: null,
            })
        }
    }

    get_viewobj_json_content = (objetAAfficher) => {
        try {
            let cheminURL;
            switch (document.domain) {
                case "localhost":
                    cheminURL = objetAAfficher.viewobj_path
                    break;
                case "argos-dev-test.itmsol.fr":
                    cheminURL = "https://" + document.domain + objetAAfficher.viewobj_path.slice(1, objetAAfficher.viewobj_path.length)
                    break;
                case "argos-v19.itmsol.fr":
                    cheminURL = "https://" + document.domain + objetAAfficher.viewobj_path.slice(1, objetAAfficher.viewobj_path.length)
                    break;
                default:
                    cheminURL = objetAAfficher.viewobj_path
            }
            fetch(cheminURL + "?varget" + Math.random())
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        viewobj_json_content: {...data},
                        type_affichage: data.type_de_representation
                    })
                });
        } catch (e) {
            console.error("error", e)
        }
    }
    gererEtatDeCibleName = (event) => this.setState({...this.state, [event.target.name]: event.target.value,});
    gererObjetEtatDeCibleName = (event) => this.setState({
        ...this.state,
        [event.target.name]: JSON.parse(event.target.value),
    });
    gererTypeAffichage = (event) => {
        let contenuJson;
        let typeDAffichage = event.target.value;
        switch (parseInt(typeDAffichage)) {
            case glossaire_type_objets_vue.rectangle_de_composantes:
                contenuJson = {
                    "type_de_representation": 0,
                    "styleMainDiv": {
                        "backgroundColor": "white",
                        "color": "black !important",
                        "height": "300px",
                        "width": "300px",
                        "position": "absolute",
                        "top": "0",
                        // "right": "0",
                        "border": "solid 2px black"
                    }
                }
                break;
            case glossaire_type_objets_vue.rectangle_de_donnees:
                contenuJson = {
                    "type_de_representation": 1,
                    "position_in_sensor": 1,
                    "styleMainDiv": {
                        "opacity": 1,
                        "position": "absolute",
                        "top": "0",
                        "right": "0",
                        "border": "solid 2px black",
                        "fontSize": "12px",
                        "backgroundColor": "white"
                    }
                }
                break;
            case glossaire_type_objets_vue.rectangle_de_couleur:
                contenuJson = {
                    "type_de_representation": 2,
                    "position_in_sensor": 1,
                    "styleMainDiv": {
                        "opacity": 1,
                        "position": "absolute",
                        "width": "100px",
                        "height": "100px",
                        "border": "solid 2px black"
                    }
                }
                break;
            case glossaire_type_objets_vue.LinearGauges:
                contenuJson = {
                    "type_de_representation": 4,
                    "position_in_sensor": 1,
                    "parametreAffichage":
                        {
                            "width": 180,
                            "height": 320,
                            "minValue": 0,
                            "maxValue": 70,
                            "majorTicks": [
                                "0",
                                "20",
                                "40",
                                "60",
                                "70"
                            ],
                            "startAngle": 90,
                            "ticksAngle": 180,
                            "valueBox": false,
                            "minorTicks": 2,
                            "strokeTicks": true,
                            "highlights": [
                                {
                                    "from": 35,
                                    "to": 70,
                                    "color": "rgba(200, 50, 50, .75)"
                                }
                            ],
                            "colorPlate": "#fff",
                            "borderShadowWidth": 0,
                            "borders": false,
                            "needleType": "arrow",
                            "needleWidth": 2,
                            "needleCircleSize": 7,
                            "needleCircleOuter": true,
                            "needleCircleInner": false,
                            "animationDuration": 1500,
                            "animationRule": "linear",
                            "barWidth": 10
                        },
                    "widget_header": "Entête",
                    "widget_footer": "Pied De Page"
                }
                break;
            case glossaire_type_objets_vue.GaugesCompass:
                contenuJson = {
                    "type_de_representation": 3,
                    "position_in_sensor_direction": 1,
                    "position_in_sensor_speed": 2,
                    "parametreAffichage":
                        {
                            "width": 300,
                            "height": 300,
                            "colorUnits": "#fff",
                            "minValue": 0,
                            "maxValue": 360,
                            "majorTicks": [
                                "N",
                                "NE",
                                "E",
                                "SE",
                                "S",
                                "SW",
                                "W",
                                "NW",
                                "N"
                            ],
                            "minorTicks": 22,
                            "ticksAngle": 360,
                            "startAngle": 180,
                            "strokeTicks": false,
                            "highlights": false,
                            "colorPlate": "#3a3",
                            "colorMajorTicks": "#fff",
                            "colorMinorTicks": "#fff",
                            "colorNumbers": "#fff",
                            "colorNeedle": "rgba(240, 128, 128, 1)",
                            "colorNeedleEnd": "rgba(255, 160, 122, 0.9)",
                            "valueBox": false,
                            "valueTextShadow": false,
                            "colorCircleInner": "#fff",
                            "colorNeedleCircleOuter": "#ccc",
                            "needleCircleSize": 15,
                            "needleCircleOuter": false,
                            "animationRule": "linear",
                            "needleType": "line",
                            "needleStart": 75,
                            "needleEnd": 99,
                            "needleWidth": 3,
                            "borders": true,
                            "borderInnerWidth": 0,
                            "borderMiddleWidth": 0,
                            "borderOuterWidth": 10,
                            "colorBorderOuter": "#ccc",
                            "colorBorderOuterEnd": "#ccc",
                            "colorNeedleShadowDown": "#222",
                            "borderShadowWidth": 0,
                            "animationDuration": 1500
                        },
                    "widget_header": "Entête",
                    "widget_footer": "Pied De Page"
                }
                break;
            case glossaire_type_objets_vue.affichageGif:
                contenuJson = {
                    "type_de_representation": 5,
                    "position_in_sensor": 1,
                    "type_image": "pompe",
                    "parametreAffichage":
                        {

                            "width": "50px",
                            "height": "50px"
                        }

                }
                break;

            case glossaire_type_objets_vue.ProgresseBar:
                contenuJson = {
                    "type_de_representation": 6,
                    "displayModeBar": false,
                    "distanceFinale": 60,
                    "margeFinale": 0,
                    "position_in_sensor": 1,
                    "layout": {
                        "title": {
                            "text": ""
                        },
                        "showlegend": false,
                        "paper_bgcolor": "rgba(0,0,0,0)",
                        "plot_bgcolor": "rgba(0,0,0,0)",
                        "height": 200,
                        "margin": {
                            "b": "35",
                            "l": "50",
                            "t": "20",
                            "r": "5"
                        }
                    },
                    "annotationPourcentage": {
                        "font": {"size": 40},
                        "showarrow": false,
                        "align": "center",
                        "xanchor": "left",
                        "x": 0.465,
                        "y": 0.1,
                        "xref": "paper",
                        "yref": "paper"
                    },
                    "gaugeStep": [
                        {
                            "range": [5, 40],
                            "color": "gray"
                        }
                    ]
                }
                break;
            default:
                contenuJson = {};
                break;
        }
        this.setState({
            ...this.state,
            viewobj_json_content: contenuJson,
            type_affichage: typeDAffichage
        })
    };
    gererRecupCoord = (coordXY) => this.setState({...this.state, coord_x: coordXY.lat, coord_y: coordXY.lng, flag_coord_reel: 0});
    gererPositionsInSensorList = (positions_in_sensor_list) => this.setState({...this.state, positions_in_sensor_list: positions_in_sensor_list});
    gererCapteurId = (sensor_id, listeComposantes) => this.setState({...this.state, sensor_id: sensor_id, listeComposantes: listeComposantes});
    gererGrapheId = (graph_id) => this.setState({...this.state, graph_id: graph_id});
    gererEnvoi = () => {
        //mise en forme de l'objet pour l'envoi de la requête API
        let formulaireObjet = {

            viewobj_type: parseInt(this.state.viewobj_type),
            viewobj_json_content: JSON.stringify(this.state.viewobj_json_content),
            flag_coord_reel: parseInt(this.state.viewobj_type) !== glossaire_type_d_objet.capteur ? 0 : 1,
            projection_id: parseInt(this.state.projection_id),
            coord_x: floatOrNull(this.state.coord_x),
            coord_y: floatOrNull(this.state.coord_y),
            coord_z: floatOrNull(this.state.coord_z),
            sensor_id: parseInt(this.state.sensor_id),
            positions_in_sensor_list: this.state.positions_in_sensor_list,
            graph_id: parseInt(this.state.graph_id),
            view_in_view_id: parseInt(this.state.view_in_view_id),
            get_values_onload: parseInt(this.state.get_values_onload),
            get_metadata_onload: parseInt(this.state.get_metadata_onload),
            ...valeur_defaut_null,
        };
        switch (parseInt(this.state.viewobj_type)) {
            case glossaire_type_d_objet.text:
                // formulaireObjet["cle"]=3;
                break;
            case glossaire_type_d_objet.capteur:
                formulaireObjet["sensor_id"] = parseInt(this.state.sensor_id);
                formulaireObjet["positions_in_sensor_list"] = this.state.positions_in_sensor_list;
                formulaireObjet["flag_coord_reel"] = parseInt(this.state.flag_coord_reel);
                if (parseInt(this.state.flag_coord_reel) === 1) {
                    formulaireObjet["projection_id"] = 0;
                    formulaireObjet["coord_x"] = 0;
                    formulaireObjet["coord_y"] = 0;
                    formulaireObjet["coord_z"] = 0;
                } else {
                    if (parseInt(this.state.flag_coord_reel) === 0 && this.state.coord_x === null) {
                        formulaireObjet["coord_x"] = 0;
                    } else if (parseInt(this.state.flag_coord_reel) === 0 && this.state.coord_y === null) {
                        formulaireObjet["coord_y"] = 0;
                    }
                }
                break;
            case glossaire_type_d_objet.graphe:
                formulaireObjet["graph_id"] = parseInt(this.state.graph_id);
                break;
            case glossaire_type_d_objet.vue:
                formulaireObjet["view_in_view_id"] = parseInt(this.state.view_in_view_id);
                break;
            default:
                formulaireObjet = null
                break;
        }
        if (formulaireObjet !== null) {
            if (this.props !== undefined && this.props.preconfigurerObjet === undefined) {
                //Envoi la requête de création d'un objet de vue
                this.props.reqAjouterObjetVue(formulaireObjet);
            } else {
                //Envoi la requête de modification d'un objet de vue
                formulaireObjet["viewobj_id"] = this.props.preconfigurerObjet.viewobj_id;
                this.props.reqModifierObjetVue(formulaireObjet);
            }
        } else {
            console.warn("Type d'objet inconnu")
        }
    }
    affichageFormulaire = (style, label_state, label_name, pattern) => {
        /**
         * permet d'afficher un composant input de gestion de la modification
         */

        switch (pattern) {
            case glossaire_pattern.array:
                return (
                    <div className={[s.col_12, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.props.preconfigurerObjet !== undefined ? JSON.stringify(this.state[label_state]) : null}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>
                    </div>
                )
            case glossaire_pattern.textareaJson:
                return (
                    <div className={[s.col_12, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <textarea
                            className={[s.form_control, S.textarea].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={JSON.stringify(this.state[label_state], null, 2)}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>
                    </div>
                )
            default:
                return (
                    <div className={[s.col_12, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{label_name}</span>
                        <input
                            type="text"
                            pattern={pattern}
                            className={[s.form_control,].join(" ")}
                            placeholder={label_state}
                            name={label_state}
                            value={this.state[label_state]}
                            disabled={this.props.affichageProfil === true}
                            onChange={(event) => this.gererEtatDeCibleName(event)}/>
                    </div>
                )
        }
    }

    render() {
        let formulaireCoordonnees;
        if (parseInt(this.state.viewobj_type) === glossaire_type_d_objet.capteur && parseInt(this.state.flag_coord_reel) === 1) {
            formulaireCoordonnees = <Auxiliaire>
                {/**DEBUT projection_id**/}
                {/*TODO: composant de sysème de coordonnées avec les données qui change en fonction du type de repère*/}
                {this.affichageFormulaire(s.col_12, "projection_id", checkMot("projection_id"), glossaire_pattern.integer)}{/**FIN projection_id**/}
            </Auxiliaire>
        } else {
            formulaireCoordonnees = <Auxiliaire>
                {/**DEBUT projection_id**/}
                {/*TODO: composant de sysème de coordonnées avec les données qui change en fonction du type de repère*/}
                {this.affichageFormulaire(s.col_12, "projection_id", checkMot("projection_id"), glossaire_pattern.integer)}{/**FIN projection_id**/}
                {/**DEBUT coord_x**/}{this.affichageFormulaire(s.col_12, "coord_x", checkMot("coord_x"), glossaire_pattern.integer)}{/**FIN coord_x**/}
                {/**DEBUT coord_y**/}{this.affichageFormulaire(s.col_12, "coord_y", checkMot("coord_y"), glossaire_pattern.integer)}{/**FIN coord_y**/}
                {/**DEBUT coord_z**/}{this.affichageFormulaire(s.col_12, "coord_z", checkMot("coord_z"), glossaire_pattern.integer)}{/**FIN coord_z**/}
            </Auxiliaire>
        }
        let formulaireTypeAffichage;
        if (parseInt(this.state.viewobj_type) === glossaire_type_d_objet.capteur) {
            {/**DEBUT viewobj_type**/
            }
            formulaireTypeAffichage = <div className={s.col_12}>
                <label className={[S.stil2].join(" ")}>{checkMot("viewobj_type")}</label>
                <select value={this.state.type_affichage}
                        onChange={(event) => this.gererTypeAffichage(event)}
                        name="type_affichage" className={[s.form_control, s.mb_2].join(" ")}
                        disabled={this.props.affichageProfil === true}
                >
                    <option value={null}>{checkMot("par_defaut")}</option>
                    <option
                        value={glossaire_type_objets_vue.rectangle_de_composantes}>{checkMot("rectangle_de_composantes")}</option>
                    <option
                        value={glossaire_type_objets_vue.rectangle_de_donnees}>{checkMot("rectangle_de_donnees")}</option>
                    <option
                        value={glossaire_type_objets_vue.rectangle_de_couleur}>{checkMot("rectangle_de_couleur")}</option>
                    <option value={glossaire_type_objets_vue.LinearGauges}>{checkMot("linear_gauges")}</option>
                    <option value={glossaire_type_objets_vue.GaugesCompass}>{checkMot("gauges_compass")}</option>
                    <option value={glossaire_type_objets_vue.affichageGif}>{checkMot("affichage_gif")}</option>
                    <option value={glossaire_type_objets_vue.ProgresseBar}>{checkMot("bar_de_progression")}</option>
                </select>
            </div>
            {/**FIN viewobj_type**/
            }
        } else {
            formulaireTypeAffichage = null
        }
        let formulaireParTypeObjet;
        switch (parseInt(this.state.viewobj_type)) {
            case glossaire_type_d_objet.text:
                formulaireParTypeObjet = <div>
                    {/*todo: entrée spécifique pour objet type text: nom, text ... (factoriser heutaur et largeur ...)*/}
                    Entrée pour TEXT
                </div>
                break;
            case glossaire_type_d_objet.capteur:
                console.log("this.state", this.state)
                console.log("this.props", this.props)
                formulaireParTypeObjet = <Auxiliaire>
                    {/**DEBUT sensor_id**/}
                    < FormulaireSelectionCapteur gererCapteurId={this.gererCapteurId} preconfigurerCapteurId={this.state.sensor_id}/>
                    {/**FIN sensor_id**/}
                    {/**DEBUT positions_in_sensor_list**/}
                    {/*todo: afficher le array sous forme de string suivant "[1,2,3]" puis reconvertir à l'envoi de la requete*/}

                    <div className={[s.col_12, S.style].join(" ")}>
                        <span className={[S.stil2,].join(" ")}>{checkMot("positions_in_sensor_list")}</span>
                        <input
                            type="text"
                            className={[s.form_control,].join(" ")}

                            value={JSON.stringify(this.state["positions_in_sensor_list"])}
                            disabled={isNaN(this.state.sensor_id)}
                            onChange={(event) => this.gererObjetEtatDeCibleName(event)}/>
                    </div>
                    <FormulaireSelectionComposantes gererPositionsInSensorList={this.gererPositionsInSensorList} disable={isNaN(this.state.sensor_id)} preconfigurerPositionsInSensorList={this.state.positions_in_sensor_list} listeComposantes={this.state.listeComposantes}/>
                    {/**DEBUT flag_coord_reel**/}
                    <div className={s.col_12}>
                        <label className={[S.stil2].join(" ")}>{checkMot("flag_coord_reel")}</label>
                        <select value={this.state.flag_coord_reel}
                                onChange={(event) => this.gererEtatDeCibleName(event)}
                                name="flag_coord_reel" className={[s.form_control, s.mb_2].join(" ")}
                                disabled={this.props.affichageProfil === true}
                        >
                            <option selected={1}
                                    value={1}>{checkMot("donnees_reelles")}</option>
                            <option selected={0}
                                    value={0}>{checkMot("donnees_fictives")}</option>
                        </select>
                    </div>
                    {/**FIN flag_coord_reel**/}
                </Auxiliaire>
                break;
            case glossaire_type_d_objet.graphe:
                formulaireParTypeObjet = <FormulaireSelectionGraphe gererGrapheId={this.gererGrapheId} preconfigurerGrapheId={this.state.graph_id}/>
                break;
            case glossaire_type_d_objet.vue:
                formulaireParTypeObjet = <Auxiliaire>
                    {/**DEBUT view_in_view_id**/}{this.affichageFormulaire(s.col_12, "view_in_view_id", checkMot("view_in_view_id"), glossaire_pattern.text)}{/**FIN view_in_view_id**/}
                </Auxiliaire>
                break;
            default:
                formulaireParTypeObjet = "type d'objet invalide"
                break;
        }
        return (
            <Auxiliaire>
                <div className={S.ConteneurFormulaireObjet}>
                    <div className={S.AffichageVue}>
                        {/*todo:
                            - afficher la vue à côté du formulaire de modification de l'objet de la vue
                            - ajouter l'information de viewobj_json_content pour afficher le json de l'objet à éditer en direct
                        */}
                        <AffichageVue view_id={this.props.view_id} uniciteVue={checkMot("ajout_objet")}
                                      recupCoord={this.gererRecupCoord}
                                      theProps={this.state.theProps}
                                      viewobj_json_content={this.state.viewobj_json_content}
                                      coord_x={this.state.coord_x}
                                      coord_y={this.state.coord_y}
                        />
                    </div>
                    <div className={[S.FormulaireObjet, S.ConteneurInfo].join(" ")}>
                        {this.props !== undefined && this.props.preconfigurerObjet === undefined
                            ? <div className={[s.card_header, hedes].join(" ")}>{/*entête de formulaire objet créer*/}
                                {checkMot("creer_un_objet")}
                            </div>
                            :
                            <div className={[s.card_header, hedes].join(" ")}>{/*entête de formulaire objet modifier*/}
                                {/* logo "i"*/}
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-info-circle-fill"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                </svg>
                                {/*l'id de la vue*/}
                                {checkMot("viewobj_id")} : {this.props.preconfigurerObjet.viewobj_id}
                            </div>
                        }

                        {/**DEBUT viewobj_type**/}
                        <div className={s.col_12}>
                            <label className={[S.stil2].join(" ")}>{checkMot("viewobj_type")}</label>
                            <select value={this.state.viewobj_type}
                                    defaultValue={this.state.viewobj_type}
                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                    name="viewobj_type" className={[s.form_control, s.mb_2].join(" ")}
                                    disabled={this.props.affichageProfil === true}
                            >
                                <option value={glossaire_type_d_objet.text}>{checkMot("text")}</option>
                                <option value={glossaire_type_d_objet.capteur}>{checkMot("capteur")}</option>
                                <option value={glossaire_type_d_objet.graphe}>{checkMot("graphe")}</option>
                                <option value={glossaire_type_d_objet.vue}>{checkMot("vue")}</option>
                            </select>
                        </div>
                        {/**FIN viewobj_type**/}
                        {/**DEBUT get_values_onload**/}
                        <div className={s.col_12}>
                            <label
                                className={[S.stil2].join(" ")}>{checkMot("get_values_onload")}</label>
                            <select value={this.state.get_values_onload}
                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                    name="get_values_onload" className={[s.form_control, s.mb_2].join(" ")}
                                    disabled={this.props.affichageProfil === true}
                            >
                                <option selected={1}
                                        value={1}>{checkMot("valeur_au_chargement")}</option>
                                <option selected={0}
                                        value={0}>{checkMot("pas_de_valeur_au_chargement")}</option>
                            </select>
                        </div>
                        {/**FIN get_values_onload**/}
                        {/**DEBUT get_metadata_onload**/}
                        <div className={s.col_12}>
                            <label
                                className={[S.stil2].join(" ")}>{checkMot("get_metadata_onload")}</label>
                            <select value={this.state.get_metadata_onload}
                                    onChange={(event) => this.gererEtatDeCibleName(event)}
                                    name="get_metadata_onload" className={[s.form_control, s.mb_2].join(" ")}
                                    disabled={this.props.affichageProfil === true}
                            >
                                <option selected={1}
                                        value={1}>{checkMot("meta_donnees_au_chargement")}</option>
                                <option selected={0}
                                        value={0}>{checkMot("pas_de_donnees_au_chargement")}</option>
                            </select>
                        </div>
                        {/**FIN get_metadata_onload**/}
                        {formulaireParTypeObjet}
                        {formulaireCoordonnees}
                        {formulaireTypeAffichage}
                        <div className={S.tStyle}>{checkMot("Catalogue des Objects")} :
                        { <Popup typeBoutonAffichage={type_btn.outil}
                           ajoutOverlay={S.Overlay}
                           icone={<FaSwatchbook size={15}/>}
                           >
                             <CatalogueObject />
                        </Popup>}

                        </div>
                        {/**DEBUT viewobj_json_content**/}
                        {this.affichageFormulaire(s.col_12, "viewobj_json_content", checkMot("viewobj_json_content"), glossaire_pattern.textareaJson)}{/**FIN viewobj_json_content**/}
                        <Bouton cliquer={this.gererEnvoi}>{checkMot("enregistrer")}</Bouton>
                    </div>
                </div>

            </Auxiliaire>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reqAjouterObjetVue: (viewobj) => dispatch(actions.reqAjouterObjetVue(ownProps.view_id, viewobj)),
        reqModifierObjetVue: (viewobj) => dispatch(actions.reqModifierObjetVue(ownProps.view_id, ownProps.preconfigurerObjet.viewobj_id, viewobj)),
    };
};

export default connect(null, mapDispatchToProps)(FormulaireObjet);
