import React, { useState, useEffect } from 'react';
import { IoIosHelpCircleOutline } from "react-icons/io";
import S from './Bouton.module.scss';

const HoverButton = ({ imageUrl, customStyle, customClass  }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [loadedImage, setLoadedImage] = useState(null);
  
    useEffect(() => {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => setLoadedImage(image);
    }, [imageUrl]);
  
    return (
        <span 
            
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={customStyle}
        >
            <IoIosHelpCircleOutline size={25} />
            {isHovering && loadedImage && (
                <img 
                    src={loadedImage.src} 
                    className={`${customClass}`}
                    alt="" 
                />
            )}
        </span>
    );
};

export default HoverButton;
