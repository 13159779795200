import React, { Component } from "react";
import * as s from "../../../ressources/StyleGlobal/StyleGlobal";
import Auxiliaire from "../../../hoc/Auxiliaire/Auxiliaire";
import { connect } from "react-redux";
import { checkMot } from "../../../ressources/functionJS/checkMot";
import * as S from "./FormulaireSelectionProjetsArborecense.module.scss";
import TextField from "@mui/material/TextField";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { FiChevronRight, FiChevronDown } from "react-icons/fi";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

import { VscCloseAll, VscError, VscUnverified } from "react-icons/vsc";
class FormulaireSelectionProjetsArborecense extends Component {
    // Initialisation de la classe FormulaireSelectionProjetsArborecense et définition de l'état initial

  state = {
    listeIdProjet  : [],
    listeNameProjet: "",
    ProjectPerent  : false,
    enlevertous    : false,
    expandedItems  : {},
    pathTree:false
  };

    // Méthode componentDidMount : s'exécute après le montage du composant dans le DOM
    // Utilisée ici pour initialiser les projets pré-configurés (si disponibles)

  componentDidMount() {
    if (this.props.preconfigurerProjectsListId !== undefined) {
      this.setState({
        ...this.state,
        listeIdProjet: this.props.preconfigurerProjectsListId,
      });
    }
  }
    // Méthode componentDidUpdate : s'exécute après la mise à jour du composant
    // Utilisée pour gérer les changements d'état et les sélections de projets

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state !== prevState) {
      this.props.gererProjetsList(this.state.listeIdProjet);
    }
  }
    // gererListeIdProjet : Gère l'ajout ou la suppression de projets de la liste
    // en fonction des actions de l'utilisateur

  gererListeIdProjet = (event) => {
      /**
     * ajoute la valeur de l'event sélectionné dans la liste déroulante
     */
    if (
      event.target.value !== "unselectable" &&
      this.state.listeIdProjet.indexOf(parseInt(event.target.value)) < 0
    ) {
        // restreint la sélection par défaut et la redondance
      let newListeIdProjet = [...this.state.listeIdProjet];

      newListeIdProjet.push(parseInt(event.target.value));
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
        // this.setState({enlevertous: true});
    } else {
      let   newListeIdProjet = [...this.state.listeIdProjet];
      const index            = this.state.listeIdProjet.indexOf(
        parseInt(event.target.value)
      );
      if (index > -1) {
        newListeIdProjet.splice(index, 1);
      }
      this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
    }
  };
    // enleverProjet : Supprime un projet spécifique de la liste des projets sélectionnés

  enleverProjet = (indInArray) => {
      /** via l'id du projet enlève le projet de la liste des projets sélectionnés
     * @idNameListe: [id, name]
     * */
      // enlève l'id du projet de la liste des id projet pour l'envoie
    let newListeIdProjet = [...this.state.listeIdProjet];
    newListeIdProjet.splice(indInArray, 1);
      //mise à jour de l'état de la liste d'ids à envoyer et de la liste d'affichage lors de la suppression d'un projet
    this.setState({ ...this.state, listeIdProjet: newListeIdProjet });
  };
    // removerTodosProjetosSelecionados : Efface tous les projets sélectionnés de la liste

  removerTodosProjetosSelecionados = () => {
    this.setState({ listeIdProjet: [] });
    this.setState({ enlevertous: false });
  };
    // developeEnfent : Gère l'expansion ou la réduction des items de projet dans l'interface utilisateur

  developeEnfent = (projectId) => {
    this.setState((prevState) => ({
      expandedItems: {
        ...prevState.expandedItems,
        [projectId]: !prevState.expandedItems[projectId],
      },
    }));
  };
    // construirArvoreProjetos : Construit un arbre de projets à partir de la liste des projets
    // en utilisant une structure parent-enfant
    PathC(){this.setState({ pathTree: true })}
  construirArvoreProjetos() {
    const projetosFiltrados = this.props.listeProjets.filter(
      (projeto) => 
        "" === this.state.listeNameProjet ||
        projeto.project_name
          .toLowerCase()
          .includes(this.state.listeNameProjet.toLowerCase())
    );

    let mapaProjetos   = new Map();
    let arvoreProjetos = [];

    projetosFiltrados.forEach((projeto) => {
      mapaProjetos.set(projeto.project_id, { ...projeto, children: [] });
    });

    projetosFiltrados.forEach((projeto) => {
      if (projeto.parent_id && mapaProjetos.has(projeto.parent_id)) {
        mapaProjetos
          .get(projeto.parent_id)
          .children.push(mapaProjetos.get(projeto.project_id));
      } else {
        arvoreProjetos.push(mapaProjetos.get(projeto.project_id));
      }
    });

    return arvoreProjetos;
  }
    // atualizarSelecaoRecursivamente : Met à jour récursivement la sélection des projets et de leurs enfants

  atualizarSelecaoRecursivamente(projeto, selecionado, novaListaIdProjet) {
    if (selecionado) {
      novaListaIdProjet.add(projeto.project_id);
      this.setState({ enlevertous: true });
    } else {
      novaListaIdProjet.delete(projeto.project_id);
    }

    if (projeto.children) {
      projeto.children.forEach((filho) => {
        this.atualizarSelecaoRecursivamente(
          filho,
          selecionado,
          novaListaIdProjet
        );
      });
    }
  }

  
  // toggleProjetoSelecao : Bascule la sélection d'un projet spécifique

  toggleProjetoSelecao(projeto) {
    this.setState((prevState) => {
      let   novaListaIdProjet = new Set(prevState.listeIdProjet);
      const selecionado       = !novaListaIdProjet.has(projeto.project_id);
      this.atualizarSelecaoRecursivamente(
        projeto,
        selecionado,
        novaListaIdProjet
      );
      return {
        ...prevState,
        listeIdProjet: Array.from(novaListaIdProjet),
      };
    });
  }
    // renderArvoreProjetos : Rendu récursif des projets et de leurs enfants sous forme d'arbre
  getChemin(project_id) {
    for (let project of this.props.listeProjets) {
      if (project.project_id === project_id) {
        if (project.parent_id === null) {
          return <span className = {S.path}>{project.project_name}</span>;
        } else {
          return [this.getChemin(project.parent_id), "  /  ", <span className = {S.path} >{project.project_name}</span>]
        }
      }
    }
  }

  renderArvoreProjetos(projetonew) {
    const isExpanded = this.state.expandedItems[projetonew.project_id];

    return (
      <div key = {projetonew.project_id}>
        <table>
          <tbody>
            <tr>
              <td style = {{ padding: "0" }}>
                {projetonew.children && projetonew.children.length > 0 && (
                  <button
                    className = {S.plusParent}
                    onClick   = {() => this.developeEnfent(projetonew.project_id)}
                  >
                    {isExpanded ? (
                      <FiChevronDown size = {20} />
                    ) : (
                      <FiChevronRight size = {20} />
                    )}
                  </button>
                )}

                <Switch
                  size      = "small"
                  className = {S.checkSlt}
                  type      = "checkbox"
                  checked   = {this.state.listeIdProjet.includes(
                    projetonew.project_id
                  )}
                  onChange      = {() => this.toggleProjetoSelecao(projetonew)}
                  onContextMenu = {(e) => {
                    e.preventDefault();
                    this.handleClickDroit(projetonew);
                  }}
                />
              </td>
              <td>{projetonew.project_id}</td>
              <td>{projetonew.project_name}</td>

            
            </tr>
          </tbody>
        </table>
      
        {projetonew.parent_id !== null && (
                <div style={{ display: this.state.pathTree ? 'block' : 'none' }} className = {S.pathTow}>
                  {" "}
                  {this.getChemin(projetonew.project_id)}
                </div>
              )}
        {projetonew.children && projetonew.children.length > 0 && (
          <div
            style={{
              marginLeft  : "30px",
              marginTop   : "-20px",
              marginBottom: "15px",
            }}
          >
            {isExpanded &&
              projetonew.children.map((child) => (
                <div key = {child.project_id} style = {{ marginBottom: "-15px" }}>
                  <MdSubdirectoryArrowRight
                    size  = {20}
                    style = {{ marginBottom: "-60px", marginLeft: "-40px" }}
                  />
                  {this.renderArvoreProjetos(child)}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  handleClickDroit = (projeto) => {
    this.setState((prevState) => {
      const novaListaIdProjet = new Set(prevState.listeIdProjet);

        // Altera a seleção com base no estado atual
      if (novaListaIdProjet.has(projeto.project_id)) {
        novaListaIdProjet.delete(projeto.project_id);
      } else {
        novaListaIdProjet.add(projeto.project_id);
      }

      return {
        ...prevState,
        listeIdProjet: Array.from(novaListaIdProjet),
      };
    });
  };
    // render : Méthode de rendu du composant, affiche l'interface de sélection des projets

  render() {
    let information_projet_liste = [];
    if (
      this.props.listeProjets  !== undefined &&
      this.state.listeIdProjet !== []
    ) {
      for (let i = 0; i < this.props.listeProjets.length; i++) {
        for (let j = 0; j < this.state.listeIdProjet.length; j++) {
          if (
            this.state.listeIdProjet[j] === 
            this.props.listeProjets[i].project_id
          ) {
            information_projet_liste.push(this.props.listeProjets[i]);
          }
        }
      }
    }
    let newButton = 
      information_projet_liste.length > 0 || this.state.enlevertous ? (
        <Button      variant = "text" onClick = {this.removerTodosProjetosSelecionados}>
        <VscCloseAll size    = {20} />
          {checkMot(" Désélectionne cette liste : ")}
        </Button>
      ) : null; // ou outro componente/elemento como fallback
    const arvoreProjetos = this.construirArvoreProjetos();
    return (
      <Auxiliaire>
        <div className = {s.table_responsive}>
          {checkMot("filtre_par")}
          <TextField
            className = {S.search}
            id        = "outlined-basic"
            variant   = "outlined"
            fullWidth
            label    = {checkMot("nom_du_project")}
            onChange = {(event) =>
              this.setState({
                ...this.state,
                listeNameProjet: event.target.value,
                pathTree:true
              })
            }
          />

          <table className = {[s.table, S.margin].join(" ")}>
            <thead>
              <tr>
                <th scope = "col">
                  {checkMot("liste_des_projets")}{" "}
                  <span
                    className = {S.infoI}
                    title     = {checkMot(
                      "clique_droit_pour_selectionner_uniquement_un_parent"
                    )}
                  >
                    <VscUnverified size = {30} />
                  </span>
                </th>
                <th scope = "col">
                  {checkMot("liste_des_projets_selectionner")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className = {S.stylecol1}>
                  {arvoreProjetos.map((projetonew) =>
                    this.renderArvoreProjetos(projetonew)
                  )}
                </td>
                <td   className = {S.fixed}>
                <span className = {S.space}>{newButton}</span>
                  {Object.keys(information_projet_liste).map(
                    (indListeProjets) => {
                      return (
                        <div
                          className = {S.labell}
                          key       = {indListeProjets}
                          onClick   = {() => this.enleverProjet(indListeProjets)}
                          style     = {{ cursor: "pointer" }}
                        >
                          <span     className = {S.iconn}>
                          <VscError size      = {15} />
                          </span>{" "}
                          {information_projet_liste[indListeProjets]
                            .project_id +
                            ", " +
                            information_projet_liste[indListeProjets]
                              .project_name}
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Auxiliaire>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    listeProjets: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(FormulaireSelectionProjetsArborecense);
